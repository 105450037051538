import {
  AtomicSearchBox,
  AtomicSearchBoxQuerySuggestions,
  AtomicSearchInterface,
  buildSearchEngine,
} from "@coveo/atomic-react";
import React from "react";
import httpClient from "../../api/httpClient";

interface GlobalSearchStandaloneProps {
  accessToken: string;
  organizationId: string;
  organizationEndpoints: {
    platform: string;
    analytics: string;
    search: string;
  };
  locale: string;
}

const GlobalSearchStandalone = ({
  accessToken,
  organizationId,
  organizationEndpoints,
  locale,
}: GlobalSearchStandaloneProps) => {
  const engine = buildSearchEngine({
    configuration: {
      search: { pipeline: "Global Search" },
      accessToken,
      organizationId,
      organizationEndpoints,
      renewAccessToken() {
        return httpClient({
          url: "/graphql",
          method: "post",
          data: JSON.stringify({
            query: "mutation { coveoJwtCreate(input: {}) { jwt } }",
          }),
        }).then((response) => response.data.data.coveoJwtCreate.jwt);
      },
    },
  });

  return (
    <div className="global-search-standalone">
      <AtomicSearchInterface
        localizationCompatibilityVersion="v4"
        languageAssetsPath="https://assets.vention.io/coveo-atomic-assets/lang"
        iconAssetsPath="https://assets.vention.io/coveo-atomic-assets/assets"
        engine={engine}
        language={locale}
      >
        <AtomicSearchBox redirectionUrl="/global-search" textarea={true}>
          <AtomicSearchBoxQuerySuggestions />
        </AtomicSearchBox>
      </AtomicSearchInterface>
    </div>
  );
};

export default GlobalSearchStandalone;
